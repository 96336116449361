import React, { useContext } from "react";
import { Formik, Form as FormikForm, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "../css/LoginPages.css";
import axios from "axios";
import { Store } from "../Store";
import { toast } from "react-toastify";
import LoadingBox from "../components/LoadingBox";
import { getError } from "../getError";
import { useNavigate } from "react-router-dom";

// Yup validation schema
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Geçerli bir e-posta giriniz")
    .required("E-posta zorunludur"),
  password: Yup.string().required("Şifre zorunludur"),
});

const UserLogin = ({ show, setShowUserRegister, setShowUserLogin }) => {
  const { dispatch } = useContext(Store);
  const navigate = useNavigate();

  const handleRegister = () => {
    setShowUserRegister(true);
    setShowUserLogin(false);
  };

  const submitHandler = async (values, { setSubmitting }) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/api/users/signin`,
        values
      );
      setSubmitting(false);
      toast.success("Hoşgeldiniz");
      dispatch({ type: "USER_SIGNIN", payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
      setShowUserLogin(false);
      navigate("/userDashboard"); // Redirect to UserDashboard
    } catch (err) {
      toast.error(getError(err));
      setSubmitting(false);
    }
  };

  return (
    <Modal
      className="px-5 w-50 m-auto"
      id="userLoginModal"
      show={show}
      onHide={() => setShowUserLogin(false)}
      animation={false}
      centered
    >
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting }) => (
          <FormikForm className="signInForm">
            <h1>Danışan Giriş </h1>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>E-Posta*</Form.Label>
              <Field
                className="form-control fs-4"
                type="email"
                name="email"
                placeholder="E-posta"
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-danger"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Şifre*</Form.Label>
              <Field
                className="form-control fs-4"
                type="password"
                name="password"
                placeholder="Şifre"
              />
              <ErrorMessage
                name="password"
                component="div"
                className="text-danger"
              />
            </Form.Group>
            <div className="signInPage">
              <Field
                className="SignInPageCheck"
                type="checkbox"
                name="remember"
                as={Form.Check}
                label="Beni hatırla"
              />
              <span
                style={{
                  color: "var(--main-color)",
                  cursor: "pointer",
                  fontWeight: "700",
                }}
                onClick={() => navigate("/forget-password")}
              >
                Şifremi Unuttum
              </span>
            </div>
            <div className="my-5 d-flex flex-column gap-2 justify-content-center">
              <Button
                type="submit"
                size="lg"
                className="w-75 text-justify text-white m-auto mb-4 bg border-0"
                disabled={isSubmitting}
              >
                {isSubmitting ? <LoadingBox /> : "Giriş Yap"}
              </Button>
              <div>
                Hesabın yok mu? Hemen{" "}
                <span
                  style={{
                    color: "var(--main-color)",
                    cursor: "pointer",
                    fontWeight: "700",
                  }}
                  onClick={handleRegister}
                >
                  Kayıt Ol
                </span>
                <p>Doldurulması zorunlu alanlar*</p>
              </div>
            </div>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default UserLogin;
