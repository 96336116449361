import React from "react";
import { Button } from "react-bootstrap";

const ButtonWhiteColor = ({ children, ...props }) => {
  const buttonStyle = {
    height: "40px",
    background: "transparent",
    // border: "1px solid #ffff",
    // borderRadius: "5px",
    border: "none",
    opacity: "1",
    fontSize: "12px",
    // fontWeight: "bold",
    lineHeight: "20px",
    fontFamily: "SF Pro Text",
    letterSpacing: "0px",
    color: "white",
    textAlign: "center",
    margin: "10px",
  };

  const textStyle = {
    height: "20px",
    fontSize: "12px",
    fontWeight: "700",
    letterSpacing: "0px",
    color: "white",
    textAlign: "center",
    opacity: "1",
  };

  return (
    <Button style={buttonStyle} {...props}>
      <span style={textStyle}>{children}</span>
    </Button>
  );
};

export default ButtonWhiteColor;
