import axios from "axios";
import { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Store } from "../Store";
import { getError } from "../getError";
import LoadingBox from "../components/LoadingBox";
import Navbar from "../components/Navbar";

const UserForgetPass = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    if (userInfo) {
      navigate("/");
    }
  }, [navigate, userInfo]);
  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/api/password-reset/user`,
        {
          email,
        }
      );
      setLoading(false);
      toast.success(data.message);
    } catch (err) {
      toast.error(getError(err));
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <Container
        style={{
          width: "315px",
          height: "400px",
          margin: "20px  auto",
          background: "#dcdcdc",
          borderRadius: "15px",
        }}
        className="small-container d-flex flex-column align-items-center"
      >
        <h1 className="mt-5">Şifremi Unuttum</h1>
        <Form
          onSubmit={submitHandler}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "78%",
          }}
        >
          <Form.Group controlId="email">
            <Form.Label
              style={{
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "center",
                width: "250px",
                height: "70px",
                margin: "32px 0 15px -6px",
              }}
            >
              Yeni şifrenizi oluşturabilmeniz için size bir bağlantı
              göndereceğiz. Lütfen mail adresinizi giriniz.
            </Form.Label>
            <Form.Control
              size="lg"
              type="email"
              placeholder="Email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />

            <Button type="submit" size="lg" className="w-100 mt-3">
              {loading ? (
                <>
                  <LoadingBox />
                </>
              ) : (
                "Gönder"
              )}
            </Button>
          </Form.Group>

          <div>
            <div
              style={{
                width: "245px",
                border: "1px solid #00242E",
                opacity: "0.5",
              }}
            />
            <p style={{ marginTop: "13px" }}>
              Hesabın yok mu? Hemen{" "}
              <span style={{ color: "#A97900" }}>kayıt ol!</span>{" "}
            </p>
          </div>
        </Form>
      </Container>
    </>
  );
};
export default UserForgetPass;
