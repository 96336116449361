import React, { useEffect, useState } from "react";
import "../css/Footer.css";
import Logo from "../assets/ssfooter-Photoroom.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faXTwitter,
  faFacebook,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import { getError } from "../getError";
import { toast } from "react-toastify";

export default function Footer() {
  const [locations, setLocations] = useState(null);

  const getLocations = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/api/locations`
      );
      setLocations(response.data);
    } catch (error) {
      toast.error(getError(error));
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  return (
    <div className="d-flex flex-column footersection">
      <div
        className="footercontent  d-flex footerfirst justify-content-between align-items-start mx-auto px-1 flex-wrap"
        style={{ maxWidth: "1288px" }}
      >
        <div className="d-flex flex-column ">
          <img
            loading="lazy"
            srcSet={Logo}
            className="footerlogo mx-auto"
            style={{
              aspectRatio: "8.33",
              width: "300px",
              height: "50px",
              borderRadius: "5px",
            }}
          />
          <p className="text-white">LEGALYX LTD</p>
          <Link
            style={{ fontWeight: "bold", color: "var(--orange-color)" }}
            className="mb-4 text-decoration-none text-lg mt-md-3"
          >
            Abone ol
          </Link>
          <div className="mobilef mt-2 text-sm text-white">
            Son gelişmelerden haberdar <br />
            <span>olmak için bize abone olun</span>
          </div>
          <div className="mobilef input-group mb-3 mt-4">
            <input
              type="email"
              className="form-control"
              placeholder="E-posta Adresi Girin"
            />
            <div className="input-group-append">
              <span className="input-group-text ">
                <i
                  className="fas fa-paper-plane d-flex align-items-center justify-content-center"
                  style={{
                    color: "var(--orange-color)",
                    height: "25px",
                    width: "30px",
                  }}
                ></i>
              </span>
            </div>
          </div>
          <div className="mobilef d-flex gap-3 mt-3 pr-md-3">
            <a
              href="https://www.linkedin.com/company/avukatlazim/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                className="socialfooter"
                icon={faLinkedin}
                style={{ width: "24px", height: "24px", color: "white" }}
              />
            </a>
            <a
              href="https://twitter.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                className="socialfooter"
                icon={faXTwitter}
                style={{ width: "24px", height: "24px", color: "white" }}
              />
            </a>
            <a
              href="https://instagram.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                className="socialfooter"
                icon={faInstagram}
                style={{ width: "24px", height: "24px", color: "white" }}
              />
            </a>
          </div>
        </div>
        <div className="footeraddress d-flex flex-column mt-2 gap-1 text-white">
          <p
            className="adresp"
            style={{ fontWeight: "bold", color: "var(--orange-color)" }}
          >
            ADRES
          </p>
          {locations &&
            locations.map((location) => (
              <div className="mt-1 text-sm" key={location._id}>
                <span>{location.address}</span> /{" "}
                <span style={{ color: "var(--orange-color)" }}>
                  {location.country}
                </span>
                <br />
                <span>
                  +{location.contact[0].contactPhone.countryCode}
                </span>{" "}
                <span>{location.contact[0].contactPhone.phoneNumber}</span>
              </div>
            ))}
        </div>
        <div className="d-flex flex-column mt-2">
          <Link
            to="#"
            className="mb-3"
            style={{
              fontWeight: "bold",
              color: "var(--orange-color)",
              textDecoration: "none",
            }}
          >
            DANIŞANLAR İÇİN
          </Link>
          <Link
            to="#"
            className="mt-2"
            style={{ color: "white", textDecoration: "none" }}
          >
            Avukatlar
          </Link>
          <Link
            to="#"
            className="mt-1"
            style={{ color: "white", textDecoration: "none" }}
          >
            Sıkça Sorulan Sorular
          </Link>
          <Link
            to="#"
            className="mt-1"
            style={{ color: "white", textDecoration: "none" }}
          >
            Veri Güvenliği
          </Link>
          <Link
            to="#"
            className="mt-1"
            style={{ color: "white", textDecoration: "none" }}
          >
            Danışan Üyelik Sözleşmesi
          </Link>
          <Link
            to="#"
            className="mt-1"
            style={{ color: "white", textDecoration: "none" }}
          >
            Danışan Aydınlatma Metni
          </Link>
        </div>
        <div className="d-flex flex-column mt-2 text-white">
          <Link
            to="#"
            className="mb-3"
            style={{
              fontWeight: "bold",
              color: "var(--orange-color)",
              textDecoration: "none",
            }}
          >
            AVUKATLAR İÇİN
          </Link>
          <Link
            to="#"
            className="mt-1"
            style={{ color: "white", textDecoration: "none" }}
          >
            Fiyatlar
          </Link>
          <Link
            to="#"
            className="mt-1"
            style={{ color: "white", textDecoration: "none" }}
          >
            Veri Güvenliği
          </Link>
          <Link
            to="#"
            className="mt-2"
            style={{ color: "white", textDecoration: "none" }}
          >
            Öne Çıkan Özellikler
          </Link>
          <Link
            to="#"
            className="mt-1"
            style={{ color: "white", textDecoration: "none" }}
          >
            Avukat Üyelik Sözleşmesi
          </Link>
          <Link
            to="#"
            className="mt-1"
            style={{ color: "white", textDecoration: "none" }}
          >
            Avukat Aydınlatma Metni
          </Link>
        </div>
        <div className="d-flex flex-column mt-2 text-white">
          <Link
            to="#"
            className="mb-3"
            style={{
              fontWeight: "bold",
              color: "var(--orange-color)",
              textDecoration: "none",
            }}
          >
            HAKKIMIZDA
          </Link>
          <Link
            to="#"
            className="mt-2"
            style={{ color: "white", textDecoration: "none" }}
          >
            Biz Kimiz?
          </Link>
          <Link
            to="#"
            className="mt-1"
            style={{ color: "white", textDecoration: "none" }}
          >
            İletişim
          </Link>
          <Link
            to="#"
            className="mt-1"
            style={{ color: "white", textDecoration: "none" }}
          >
            Çerezlere İlişkin Aydınlatma Metni
          </Link>
        </div>
      </div>
      <div className="align-items-center px-3 pt-3 pb-3 mt-4  text-white footercopyright text-center w-100">
        © 2024 JuniusTech Teknoloji A.Ş. Tüm hakları saklıdır.
      </div>
    </div>
  );
}
