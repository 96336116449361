import React from "react";
import "../../css/services.css";

const ServicesCard = ({ title, description, icon: Icon }) => {
  return (
    <div className="serviceCard d-flex align-items-center justify-content-center flex-column text-center bg-white rounded-5 position-relative">
      <h1>{title}</h1>
      <p>{description}</p>
      <div className="serviceIcons position-absolute">
        <Icon style={{ fontSize: "65px" }} />
      </div>
    </div>
  );
};

export default ServicesCard;
