const Accordiondata = [
  {
    question: "Danışmanlık ücreti nasıl belirlenir?",
    answer:
      "İlk danışma görüşmemizden sonra bizimle devam etmek isterseniz, sizin için davanızın türüne göre takip ve sonuç ücretlendirmesi raporu hazırlanır.",
  },
  {
    question: "Dosyalarımı hukuki danışman ile paylaşabilir miyim?",
    answer:
      "Danışmak istediğiniz konu ve ilgili incelenmesi gereken bir belge bulunması halinde, belgeler info@avukatlazim.com mail adresine gönderilir.",
  },
  {
    question: "Dosyam ile ilgili süreçten nasıl haberdar olabilirim?",
    answer:
      "Daha fazla bilgi icin info@avukatlazim.com adresine ulasabilirsiniz.",
  },
];

export default Accordiondata;
