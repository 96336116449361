import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/videosection.css";

const VideoSection = () => {
  return (
    <div className="videomain">
      <Container className="videosection" fluid>
        <Row>
          <Col md={6}>
            <h2 className="videoh2 mb-5">Avukatlazim.com'un Amacı Nedir?</h2>
            <p className="videop">
              Üye avukatlarımızın mahremiyetini %100 güvence altına alıyoruz.
              Bugün, geniş bir çerçevede hukuk hizmeti vererek, en verimli
              şekilde çözüme ulaşmak amacıyla çalışma alanlarına göre
              uzmanlaşmış departmanlara ayrılarak organize olmaktayız. Farklı
              hukuk alanlarında uzmanlaşmış bir danışman kadrosuna ve dünya
              dinamizmini yansıtan güçlü bir danışan ağına sahibiz.
            </p>
          </Col>
          <Col md={6}>
            <div className="video-container">
              <video width="100%" height="auto" controls>
                <source src="video.mp4" type="video/mp4" />
              </video>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default VideoSection;
