const Accordiondata2 = [
  {
    question: "Hukuki danışmanlık nedir?",
    answer:
      "Hukuki danışmanlık, bireylerin veya işletmelerin karşılaştıkları hukuki sorunlar hakkında bilgi ve tavsiye alma sürecidir. Bu hizmet, yasal haklar, yükümlülükler, sözleşmeler, davalar ve diğer hukuki konularda rehberlik sağlar.",
  },
  {
    question: "Sürekli hukuki danışmanlık hizmeti alabilir miyiz?",
    answer:
      "Danışmanlık büromuz gerçek kişilere, şirketlere ve çeşitli kurum ve kuruluşlara sürekli hukuki danışmanlık hizmeti sağlamaktadır.",
  },
  {
    question: "Davalar uzun sürer mi?",
    answer:
      "Dava süresi, dava türü ve davanın bulunduğu mahkeme ve ülkeye göre değişmektedir.",
  },
];

export default Accordiondata2;
