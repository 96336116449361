import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { getError } from "../../getError";
import LoadingBox from "../LoadingBox";
import { Form } from "react-bootstrap";
import { Store } from "../../Store";

const LawyerPersonalDetails = () => {
  const [email, setEmail] = useState("");
  const [phoneRegion, setPhoneRegion] = useState("+90");
  const [phoneNo, setPhoneNo] = useState("");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [addressDescription, setAddressDescription] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressTown, setAddressTown] = useState("");
  const [addressDistrict, setAddressDistrict] = useState("");
  const [addressCode, setAddressCode] = useState("");
  const [barNo, setBarNo] = useState("");

  const { state } = useContext(Store);
  const { lawyerInfo } = state;

  useEffect(() => {
    const fetchLawyerData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/api/lawyers/${lawyerInfo._id}`,
          {
            headers: { Authorization: `Bearer ${lawyerInfo.token}` },
          }
        );

        setName(data.name);
        setSurname(data.surname);
        setEmail(data.email);
        setPhoneRegion(data?.phone?.countryCode);
        setPhoneNo(data?.phone?.phoneNumber);
        setBarNo(data?.barNo || "");
        if (data?.address) {
          setAddressDescription(data?.address.description);
          setAddressCity(data?.address.city);
          setAddressTown(data?.address.town);
          setAddressDistrict(data?.address.district);
          setAddressCode(data?.address.code);
        }
      } catch (error) {
        toast.error(getError(error));
      }
    };

    fetchLawyerData();
  }, [lawyerInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.put(
        `${process.env.REACT_APP_BASE_URI}/api/lawyers/${lawyerInfo._id}`,
        {
          name,
          surname,
          email,
          phone: {
            countryCode: phoneRegion,
            phoneNumber: phoneNo,
          },
          address: {
            description: addressDescription,
            city: addressCity,
            town: addressTown,
            district: addressDistrict,
            code: addressCode,
          },
          identityNo: barNo,
        },
        {
          headers: { Authorization: `Bearer ${lawyerInfo.token}` },
        }
      );
      setLoading(false);
      toast.success("Bilgileriniz Güncellendi.");
    } catch (error) {
      toast.error(getError(error));
      setLoading(false);
    }
  };

  return (
    <div className="lawyerdashboardpersonaldetail" style={{ width: "650px" }}>
      <div className="lawyerdashboardregisterBaslık">
        <h1>Kişisel Bilgiler</h1>
      </div>
      <form
        className="lawyerpersonaldetailFormDiv"
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
        onSubmit={submitHandler}
      >
        <div className="row" id="registerRowDiv">
          <div className="col">
            <label className="lawyerdashboard-registerLabel" htmlFor="">
              Ad
            </label>
            <input
              type="text"
              className="lawyerdashboard-registerFormControl"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label className="lawyerdashboard-registerLabel" htmlFor="">
              Soyad
            </label>
            <input
              className="lawyerdashboard-registerFormControl"
              type="text"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </div>
          <div className="col">
            <div className="d-flex justify-content-start">
              <label
                className="lawyerdashboard-registerLabel"
                style={{ width: "55px" }}
                htmlFor=""
              >
                E-posta{" "}
              </label>
              <label
                className="lawyerdashboard-registerLabel"
                style={{ color: "var(--main-color)", width: "125px" }}
              >
                (E-posta değiştir)
              </label>
            </div>
            <input
              className="lawyerdashboard-registerFormControl"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="d-flex justify-content-start">
              <label
                className="lawyerdashboard-registerLabel"
                style={{ width: "100px" }}
                htmlFor=""
              >
                Tel. Numarası
              </label>
              <label
                className="lawyerdashboard-registerLabel"
                style={{ color: "var(--main-color)", width: "130px" }}
              >
                (Numara değiştir)
              </label>
            </div>
            <div className="registerTelDiv d-flex ">
              <select
                style={{ width: "52px", height: "40px" }}
                className="lawyerdashboard-registerFormControl-phone"
                value={phoneRegion}
                id="inputGroupSelect02"
                onChange={(e) => setPhoneRegion(e.target.value)}
              >
                <option value="+1">+1</option>
                <option value="+31">+31</option>
                <option value="+32">+32</option>
                <option value="+44">+44</option>
                <option value="+90">+90</option>
                <option value="+971">+971</option>
              </select>
              <input
                className="lawyerdashboard-registerFormControl-phone"
                style={{ paddingLeft: "5px" }}
                type="text"
                value={phoneNo}
                placeholder=""
                onChange={(e) => setPhoneNo(e.target.value)}
              />
            </div>
            <br />
          </div>
        </div>
        <Form.Group
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            margin: "5px 0px",
          }}
        >
          <Form.Label>Adres</Form.Label>
          <Form.Control
            placeholder="Adres Açıklaması"
            value={addressDescription}
            onChange={(e) => setAddressDescription(e.target.value)}
          />
          <Form.Control
            placeholder="Şehir"
            value={addressCity}
            onChange={(e) => setAddressCity(e.target.value)}
          />
          <Form.Control
            placeholder="İlçe"
            value={addressTown}
            onChange={(e) => setAddressTown(e.target.value)}
          />
          <Form.Control
            placeholder="Mahalle"
            value={addressDistrict}
            onChange={(e) => setAddressDistrict(e.target.value)}
          />
          <Form.Control
            placeholder="Posta Kodu"
            value={addressCode}
            onChange={(e) => setAddressCode(e.target.value)}
          />
        </Form.Group>
        <div className="lawyerdashboard-buttons">
          <button className="lawyerdashboard-button-vazgec" type="button">
            Vazgeç
          </button>
          <button className="lawyerdashboard-button-kaydet" type="submit">
            {loading ? <LoadingBox /> : "Kaydet"}
          </button>
        </div>
        <br />
        <br />
      </form>
    </div>
  );
};

export default LawyerPersonalDetails;
