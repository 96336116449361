import "../css/reference.css";
import React from "react";
import SwipeToSlide from "./slickslider";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CenterMode from "./slickslider";

const Reference = () => {
  return (
    <>
      <div className="reference">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="referencetitle">Referanslar</h1>
        </div>
        <hr className="hrref" />
        <div className="mt-5">
          <CenterMode />
        </div>
      </div>
    </>
  );
};

export default Reference;
