import React, { useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import "../css/LoginPages.css";
import axios from "axios";
import { Store } from "../Store";
import { toast } from "react-toastify";
import LoadingBox from "../components/LoadingBox";
import { useNavigate } from "react-router-dom";
import { getError } from "../getError";
import { Formik, Field, Form as FormikForm, ErrorMessage } from "formik";
import * as Yup from "yup";

const LawyerLogin = ({ show, setShowLawyerLogin, setShowLawyerRegister }) => {
  const { dispatch } = useContext(Store);
  const navigate = useNavigate();

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Geçersiz e-posta adresi")
      .required("E-posta zorunludur"),
    password: Yup.string().required("Şifre zorunludur"),
  });

  const submitHandler = async (values, { setSubmitting }) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/api/lawyers/signin`,
        {
          email: values.email,
          password: values.password,
        }
      );
      toast.success("Hoşgeldiniz");
      dispatch({ type: "LAWYER_SIGNIN", payload: data });
      localStorage.setItem("lawyerInfo", JSON.stringify(data));
      setShowLawyerLogin(false);
      if (data.isAdmin) {
        navigate("/adminDashboard");
      } else {
        navigate("/lawyerDashboard");
      }
    } catch (err) {
      toast.error(getError(err));
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => setShowLawyerLogin(false)}
      className="px-5 m-auto p-5"
      animation={false}
      centered
    >
      <Formik
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {({ isSubmitting }) => (
          <FormikForm className="signInForm">
            <h1>Avukat Giriş</h1>
            <Form.Group className="mb-3" controlId="email">
              <Form.Label>E-Posta*</Form.Label>
              <Field
                className="fs-4"
                type="email"
                name="email"
                placeholder="E-posta"
                as={Form.Control}
              />
              <Form.Control.Feedback type="invalid">
                <ErrorMessage name="email" />
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="password">
              <Form.Label>Şifre*</Form.Label>
              <Field
                className="fs-4"
                type="password"
                name="password"
                placeholder="Şifre"
                as={Form.Control}
              />
              <Form.Control.Feedback type="invalid">
                <ErrorMessage name="password" />
              </Form.Control.Feedback>
            </Form.Group>
            <div className="signInPage">
              <Form.Check
                className="SignInPageCheck"
                type="switch"
                id="custom-switch"
                label="Beni hatırla"
              />
              <span
                style={{
                  color: "var(--main-color)",
                  cursor: "pointer",
                  fontWeight: "700",
                }}
                onClick={() => navigate("/forget-password-law")}
              >
                Şifremi Unuttum
              </span>
            </div>
            <div className="my-5 d-flex flex-column gap-2 justify-content-center">
              <Button
                type="submit"
                size="lg"
                className="w-75 text-white m-auto mb-4 bg border-0"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <LoadingBox />
                  </>
                ) : (
                  "Giriş Yap"
                )}
              </Button>
              <div>
                Hesabın yok mu? Hemen{" "}
                <span
                  style={{
                    color: "var(--main-color)",
                    cursor: "pointer",
                    fontWeight: "700",
                  }}
                  onClick={() => {
                    setShowLawyerRegister(true);
                    setShowLawyerLogin(false);
                  }}
                >
                  Kayıt Ol
                </span>
                <p>Doldurulması zorunlu alanlar*</p>
              </div>
            </div>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};

export default LawyerLogin;
