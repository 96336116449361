import React, { useContext, useState } from "react";
import eye from "../../assets/eye.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { getError } from "../../getError";
import LoadingBox from "../LoadingBox";
import { Store } from "../../Store";

const LawyerPasswordChange = () => {
  const [passwords, setPasswords] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { state } = useContext(Store);
  const { lawyerInfo } = state;


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!passwords.newPassword || !passwords.confirmPassword || !passwords.oldPassword) {
        toast.error("Tum alanlar doldurulmalidir");
        setLoading(false);
        return;
    }

    if (passwords.newPassword !== passwords.confirmPassword) {
      toast.error("Sifreler eşleşmiyor");
      setLoading(false);
      return;
    }

    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BASE_URI}/api/password-reset/lawyer/change/${lawyerInfo._id}`,
        {
          oldPassword: passwords.oldPassword,
          newPassword: passwords.newPassword,
          confirmPassword: passwords.confirmPassword,
        },{
          headers: { Authorization: `Bearer ${lawyerInfo.token}` },
        }
      );
      toast.success(data.message);
    } catch (error) {
      toast.error(getError(error));
    } finally{
      setLoading(false);
    }
  };

  console.log(passwords);

  return (
    <div>
      <div className="registerBaslık">
        <h1>Şifre Degiştir</h1>
      </div>
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "50vw",
          margin:"auto",
          height: "50vh",
          gap: "12px",
        }}
        onSubmit={submitHandler}
      >
        <div className="col-md-6">
          <label htmlFor="">
            Eski Şifre{" "}
            <label
              style={{
                color: "#a97900",
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              {" "}
            </label>
          </label>
          <div className="lawyeraccountsetting-input">
            <input
              className="lawyerdashboard-registerFormControl"
              name="oldPassword"
              type={showPassword ? "text" : "password"}
              value={passwords.oldPassword}
              placeholder="Şifre"
              onChange={handleChange}
            />
            <img
              src={eye}
              alt="Toggle Password Visibility"
              onClick={togglePasswordVisibility}
              style={{ cursor: "pointer", marginLeft: "-25px" }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label htmlFor="">
            Yeni Şifre{" "}
            <label
              style={{
                color: "#a97900",
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              {" "}
            </label>
          </label>
          <div className="lawyeraccountsetting-input">
            <input
              className="lawyerdashboard-registerFormControl"
              name="newPassword"
              type={showPassword ? "text" : "password"}
              value={passwords.newPassword}
              placeholder="Şifre"
              onChange={handleChange}
            />
            <img
              src={eye}
              alt="Toggle Password Visibility"
              onClick={togglePasswordVisibility}
              style={{ cursor: "pointer", marginLeft: "-25px" }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <label htmlFor="">
            Yeni Şifre Tekrar{" "}
            <label
              style={{
                color: "#a97900",
                backgroundColor: "transparent",
                border: "none",
              }}
            >
              {" "}
            </label>
          </label>
          <div className="lawyeraccountsetting-input">
            <input
              className="lawyerdashboard-registerFormControl"
              name="confirmPassword"
              type={showPassword ? "text" : "password"}
              value={passwords.confirmPassword}
              placeholder="Şifre"
              onChange={handleChange}
            />
            <img
              src={eye}
              alt="Toggle Password Visibility"
              onClick={togglePasswordVisibility}
              style={{ cursor: "pointer", marginLeft: "-25px" }}
            />
          </div>
        </div>
        <div className="col-md-6 mt-2">
          <button
            className="lawyerdashboard-button-kaydet"
            style={{ width: "280px" }}
            type="submit"
          >
            {loading ? (
              <>
                <LoadingBox />
              </>
            ) : (
              "Degiştir"
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default LawyerPasswordChange;
