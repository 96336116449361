import React, { useContext, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Store } from "../../Store";
import axios from "axios";
import { toast } from "react-toastify";
import { getError } from "../../getError";

const UserPersonalDetail = () => {
  const [phoneRegion, setPhoneRegion] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [addressDescription, setAddressDescription] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressTown, setAddressTown] = useState("");
  const [addressDistrict, setAddressDistrict] = useState("");
  const [addressCode, setAddressCode] = useState("");
  const [identificationNumber, setIdentityNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const { state } = useContext(Store);
  const { userInfo } = state;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/api/users/${userInfo._id}`,
          {
            headers: { Authorization: `Bearer ${userInfo.token}` },
          }
        );
        setName(data.name);
        setSurname(data.surname);
        setPhoneRegion(data.phone.countryCode);
        setPhoneNo(data.phone.phoneNumber);
        setAddressDescription(data?.address?.description || "");
        setAddressCity(data?.address?.city || "");
        setAddressTown(data?.address?.town || "");
        setAddressDistrict(data?.address?.district || "");
        setAddressCode(data?.address?.code || "");
        setIdentityNumber(data?.identificationNumber || "");
        console.log(data);
      } catch (error) {
        toast.error(getError(error));
      }
    };

    fetchUserData();
  }, [userInfo]);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BASE_URI}/api/users/${userInfo._id}`,
        {
          name,
          surname,
          phone: {
            countryCode: phoneRegion,
            phoneNumber: phoneNo,
          },
          address: {
            description: addressDescription,
            city: addressCity,
            town: addressTown,
            district: addressDistrict,
            code: addressCode,
          },
          identificationNumber,
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );
      setLoading(false);
      toast.success("Bilgileriniz Güncellendi.");
    } catch (error) {
      toast.error(getError(error));
      setLoading(false);
    }
  };

  return (
    <div
      className="userDashboardRight"
      style={{ position: "relative", margin: "20px", width: "650px" }}
    >
      <p
        style={{
          font: "normal normal bold 20px/24px SF Pro Text",
          marginBottom: "5%",
        }}
      >
        Kişisel Bilgiler
      </p>
      <Form
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Form.Group>
          <Form.Label>Ad</Form.Label>
          <Form.Control
            placeholder="Ad"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Soyad</Form.Label>
          <Form.Control
            placeholder="Soyad"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Eposta</Form.Label>
          <Form.Control
            placeholder="E-Posta"
            value={userInfo.email}
            type="email"
            disabled
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Şifre*</Form.Label>
          <Form.Control placeholder="Şifre" type="password" />
        </Form.Group>
        <Form.Label>Telefon</Form.Label>
        <Form.Group style={{ display: "flex" }}>
          <Form.Control
            style={{ width: "15%", marginRight: "2%" }}
            placeholder="+90"
            value={phoneRegion}
            onChange={(e) => setPhoneRegion(e.target.value)}
          />
          <Form.Control
            value={phoneNo}
            onChange={(e) => setPhoneNo(e.target.value)}
            placeholder="555 444 33 22"
          />
        </Form.Group>
        <Form.Group
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            margin: "5px 0px",
          }}
        >
          <Form.Label>Adres</Form.Label>
          <Form.Control
            placeholder="Adres Açıklaması"
            value={addressDescription}
            onChange={(e) => setAddressDescription(e.target.value)}
          />
          <Form.Control
            placeholder="Şehir"
            value={addressCity}
            onChange={(e) => setAddressCity(e.target.value)}
          />
          <Form.Control
            placeholder="İlçe"
            value={addressTown}
            onChange={(e) => setAddressTown(e.target.value)}
          />
          <Form.Control
            placeholder="Mahalle"
            value={addressDistrict}
            onChange={(e) => setAddressDistrict(e.target.value)}
          />
          <Form.Control
            placeholder="Posta Kodu"
            value={addressCode}
            onChange={(e) => setAddressCode(e.target.value)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Kimlik/Pasaport No</Form.Label>
          <Form.Control
            placeholder="Kimlik/Pasaport No"
            value={identificationNumber}
            onChange={(e) => setIdentityNumber(e.target.value)}
          />
        </Form.Group>
        <div
          className="userdashboardButtons"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <Button
            style={{
              border: "1px solid #00242E",
              background: "white",
              color: "black",
              width: "80px",
              height: "35px",
              borderRadius: "8px",
            }}
          >
            Vazgeç
          </Button>
          <Button
            style={{
              marginLeft: "6%",
              border: "none",
              background: "#2d3657 0% 0% no-repeat padding-box",
              width: "80px",
              height: "35px",
              borderRadius: "8px",
              color: "white",
            }}
            onClick={submitHandler}
            disabled={loading}
          >
            {loading ? "Kaydediliyor..." : "Kaydet"}
          </Button>
        </div>
      </Form>
      <p
        className="deleteAccount"
        style={{
          font: "normal normal medium 19px/23px SF Pro Text",
          textAlign: "center",
          color: "#2d3657",
          position: "relative",
          bottom: "-55%",
          left: "40%",
          opacity: "0.5",
          cursor: "pointer",
        }}
      >
        Kullanıcı Hesabımı Sil
      </p>
    </div>
  );
};

export default UserPersonalDetail;
