import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Store } from "../../Store";
import { toast } from "react-toastify";
import { getError } from "../../getError";
import PDFViewer from "./PDFViewer";

const AllLawyers = ({ handleDateClick }) => {
  const [lawyers, setLawyers] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isView, setIsView] = useState(false);
  const [clickedButtonIndex, setClickedButtonIndex] = useState(null);
  const { state } = useContext(Store);
  const { lawyerInfo } = state;

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URI}/api/lawyers`, {
        headers: { Authorization: `Bearer ${lawyerInfo?.token}` },
      })
      .then((response) => {
        setLawyers(response.data);
      })
      .catch((error) => {
        toast.error(getError(error));
      });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const getDisplayedDates = () => {
    const startIndex = (currentPage - 1) * 15;
    const endIndex = startIndex + 15;
    return lawyers.slice(startIndex, endIndex);
  };

  const handleDetailClick = (lawyer) => {
    const detailsUrl = `/lawyer-details/${lawyer._id}`;
    window.open(detailsUrl, "_blank");
  };

  return (
    <div style={{ padding: "10px", color: " #00242E" }}>
      <p
        style={{
          font: "normal normal bold 20px/24px SF Pro Text",
        }}
      >
        Avukatlar
      </p>
      <table style={{ width: "100%", marginTop: "25px" }}>
        <thead>
          <tr
            style={{
              font: " normal normal bold 16px/19px SF Pro Text",
              height: "25px",
            }}
          >
            <th
              style={{
                border: "1px solid #2d3657",
                width: "148px",
                height: "33px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Avukat Adı
            </th>
            <th
              style={{
                border: "1px solid #2d3657",
                width: "145px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Branş
            </th>
            <th
              style={{
                border: "1px solid #2d3657",
                width: "160px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Yer
            </th>
            <th
              style={{
                border: "1px solid #2d3657",
                width: "160px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              CV
            </th>
            <th
              style={{
                border: "1px solid #2d3657",
                width: "160px",
                opacity: "0.75",
                paddingLeft: "7px",
              }}
            >
              Detay
            </th>
          </tr>
        </thead>
        <tbody style={{ font: "normal small normal 14px/16px SF Pro Text" }}>
          {getDisplayedDates().map((lawyer, index) => (
            <tr
              key={lawyer._id}
              onClick={() => handleDateClick(lawyer, index)}
              style={{
                borderBottom: "1px solid #2d3657",
                paddingTop: "10px",
                paddingBottom: "10px",
                font: "normal normal normal 14px/16px SF Pro Text",
                backgroundColor:
                  selectedRow === index ? "#2d3657" : "transparent",
              }}
            >
              <td
                style={{
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              >
                {lawyer.name}
              </td>
              <td
                style={{
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              >
                {lawyer.branchId.title}
              </td>
              <td
                style={{
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              >
                {lawyer.locationId.country}
              </td>
              <td
                style={{
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              >
                <PDFViewer
                  pdfURL={`${process.env.REACT_APP_BASE_URI}/uploads/pdf/${lawyer.resume}`}
                  isView={isView}
                  setIsView={setIsView}
                />
              </td>
              <td
                style={{
                  paddingTop: "7px",
                  paddingBottom: "7px",
                }}
              >
                <button
                  style={{
                    backgroundColor:
                      clickedButtonIndex === index ? "#ebc72b" : "#2d3657",
                    color: "#fff",
                    padding: "5px 10px",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDetailClick(lawyer);
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.backgroundColor =
                      clickedButtonIndex === index ? "#ebc72b" : "#fee98e";
                    e.currentTarget.style.color = "#000";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.backgroundColor =
                      clickedButtonIndex === index ? "#ebc72b" : "#2d3657";
                    e.currentTarget.style.color = "#fff";
                  }}
                >
                  Detay
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div
        style={{ marginTop: "10px", display: "flex", justifyContent: "end" }}
      >
        {Array(Math.ceil(lawyers.length / 15))
          .fill(0)
          .map((_, i) => (
            <button
              key={i}
              style={{
                border: currentPage === i + 1 ? "none" : "1px solid #00242E",
                background: currentPage === i + 1 ? "#2d3657" : "",
                borderRadius: "14px",
                font: "normal normal normal 16px SF Pro Text",
                padding: "2px 6px",
                color: currentPage === i + 1 ? "#FFFFFF" : "#00242e",
                marginRight: "5px",
              }}
              onClick={() => setCurrentPage(i + 1)}
            >
              {i + 1}
            </button>
          ))}
      </div>
    </div>
  );
};

export default AllLawyers;
