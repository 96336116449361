import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { getError } from "../../getError";
import LoadingBox from "../LoadingBox";
import eye from "../../assets/eye.svg";
import { useNavigate } from "react-router-dom";
import { Store } from "../../Store";

const LawyerAccountSettings = ({ show, setShowLawyerLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [branch, setBranch] = useState("");
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [showLawyerRegister, setShowLawyerRegister] = useState(false);
  const [showAddButton, setShowAddButton] = useState(true);
  const [website, setWebsite] = useState("");
  const [barNo, setBarNo] = useState("");
  const [registeredBar, setRegisteredBar] = useState(false);

  const handleLogin = () => {
    setShowLawyerLogin(true);
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URI}/api/branchs`)
      .then((response) => {
        setBranches(response.data);
      })
      .catch((error) => {
        toast.error(getError(error));
      });
  }, []);

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_BASE_URI}/api/lawyers`,
        {
          barNo,
          registeredBar,
          branch,
        }
      );
      setLoading(false);
      toast.success("Emailinize doğrulama linki gönderildi.");
      setShowLawyerRegister(false);
    } catch (error) {
      toast.error(getError(error));
      setLoading(false);
    }
  };
  const { state } = useContext(Store);
  const { lawyerInfo } = state;
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const navigateRegister = () => {
    navigate("/api/lawyers/signup");
  };
  const handleAddBranch = () => {
    setShowAddButton(false);
  };

  return (
    <div className="lawyeraccountsettingmain">
      <div className="registerBaslık">
        <h1>Hesap Ayarları</h1>
      </div>
      <form
        className="lawyerRegisterFormDiv"
        style={{ height: "fit-content" }}
        onSubmit={submitHandler}
      >
        <div className="row">
          <div className="col-md-6">
            <label htmlFor="">
              Şifre{" "}
              <label
                style={{
                  color: "#a97900",
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                {" "}
              </label>
            </label>
            <div className="lawyeraccountsetting-input">
              <input
                className="lawyerdashboard-registerFormControl"
                type={showPassword ? "text" : "password"}
                value={password}
                placeholder="Şifre"
                onChange={(e) => setPassword(e.target.value)}
              />
              <img
                src={eye}
                alt="Toggle Password Visibility"
                onClick={togglePasswordVisibility}
                style={{ cursor: "pointer", marginLeft: "-25px" }}
              />
            </div>
          </div>
          <div className="col-md-6">
            <label htmlFor="">
              Baro Sicil Numarası
              <label
                style={{
                  color: "#a97900",
                  backgroundColor: "transparent",
                  border: "none",
                }}
              >
                {" "}
                .
              </label>
            </label>
            <div className="lawyeraccountsetting-input">
              <input
                className="lawyerdashboard-registerFormControl"
                type={"text"}
                value={lawyerInfo?.barNo}
                placeholder="Baro Sicil Numarası"
                onChange={(e) => setBarNo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            <label
              className="lawyeraccountsetting-input"
              style={{ widht: "20px", display: "flex", marginTop: "15px" }}
              htmlFor=""
            >
              Branş
            </label>

            <select
              className="lawyerdashboard-registerFormControl"
              value={lawyerInfo.branch}
              name="branch"
              onChange={() => {}}
              title="Branş Seç"
              id="navbarScrollingDropdown"
            >
              <option defaultValue="all">Branş Seç</option>
              {branches
                ?.sort((a, b) => a.title.localeCompare(b.title))
                .map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.title
                      .split(" ")
                      .map((word, index) => {
                        if (index === 0) {
                          return word.charAt(0).toUpperCase() + word.slice(1);
                        } else {
                          return (
                            word.charAt(0).toUpperCase() +
                            word.slice(1).toLowerCase()
                          );
                        }
                      })
                      .join(" ")}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-6">
            <label
              htmlFor=""
              style={{ widht: "20px", display: "flex", marginTop: "15px" }}
            >
              Kayıtlı Olunan Baro
              <label
                style={{
                  color: "#a97900",
                  backgroundColor: "transparent",
                  border: "none",
                }}
              ></label>
            </label>
            <div className="lawyeraccountsetting-input">
              <input
                className="lawyerdashboard-registerFormControl"
                type={"text"}
                value={lawyerInfo?.barNo}
                onChange={(e) => setRegisteredBar(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-6">
            {/* <label
              className="lawyeraccountsetting-input"
              style={{ widht: "20px", display: "flex", marginTop: "15px" }}
              htmlFor=""
            >
              Web Sitesi
            </label>

            <input
              placeholder="Bağlantı Ekle"
              className="lawyerdashboard-registerFormControl"
              type="url"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
            /> */}
          </div>
        </div>

        <div>
          <h1
            style={{
              marginLeft: "0 px",
              marginTop: "40px",
              marginBottom: "10px",
            }}
          >
            Randevu ve Sistem Bildirimleri
          </h1>
        </div>
        <div className="d-flex">
          <div>
            <div
              className="d-flex justify-content-start align-items-center"
              style={{ width: "274px", display: "flex", alignItems: "center" }}
            >
              <p className="p-2" style={{ width: "183px" }}>
                E-posta bildirimlerini aç
              </p>
              <label className="switch" style={{ marginLeft: "30px" }}>
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </div>
            <div
              className="d-flex justify-content-start align-items-center"
              style={{ width: "274px" }}
            >
              <p className="p-2" style={{ width: "183px" }}>
                SMS bildirimlerini aç
              </p>
              <label className="switch" style={{ marginLeft: "30px" }}>
                <input type="checkbox" />
                <span className="slider round"></span>
              </label>
            </div>
          </div>
          <div className="search-left-border"></div>
          <div className="d-flex" style={{ width: "274px" }}>
            {/* <label
              className="align-items-center"
              style={{
                color: "#a97900",
                marginLeft: "5px",
                marginTop: "28px",
                backgroundColor: "transparent",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => navigate("")}
            >
              Üyelik Planını Yükselt
            </label> */}
          </div>
        </div>
        <div className="lawyerdashboard-buttons" style={{ marginTop: "130px" }}>
          <button
            className="lawyerdashboard-button-vazgec"
            onClick={() => setShowLawyerRegister()}
          >
            Vazgeç
          </button>
          <button className="lawyerdashboard-button-kaydet" type="submit">
            {loading ? (
              <>
                <LoadingBox />
              </>
            ) : (
              "Kaydet"
            )}
          </button>
        </div>
        <br />
        <br />
        <p className="d-flex justify-conter">
          <button
            style={{
              color: "#a97900",
              fontSize: "19px",
              margin: "auto",
              backgroundColor: "transparent",
              border: "none",
              cursor: "pointer",
            }}
            onClick={() => handleLogin()}
          >
            Avukat Hesabımı Sil
          </button>
        </p>
      </form>
    </div>
  );
};

export default LawyerAccountSettings;
