import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { Store } from "./Store";

const ProtectedRoute = ({ children, allowedRoles }) => {
  const {state} = useContext(Store);
  const { userInfo, lawyerInfo } = state;


  if (!userInfo && !lawyerInfo) {
    return <Navigate to="/" />;
  }

  if (userInfo && allowedRoles.includes("user") && !lawyerInfo) {
    return children
  }

  if (lawyerInfo && allowedRoles.includes("lawyer") && !lawyerInfo?.isAdmin) {
    return children
  }

  if (lawyerInfo && allowedRoles.includes("admin") && lawyerInfo?.isAdmin) {
    return children
  }

  return <Navigate to="/" />;
};

export default ProtectedRoute;
