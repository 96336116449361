import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Appointments = ({ requests }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [timeSlots, setTimeSlots] = useState([]);

  // Generate time slots based on the selected date
  useEffect(() => {
    const generateTimeSlots = () => {
      const slots = [];
      for (let hour = 8; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
          slots.push(new Date(selectedDate.setHours(hour, minute, 0, 0)));
        }
      }
      return slots;
    };
    setTimeSlots(generateTimeSlots());
  }, [selectedDate]);

  // Check if a slot is booked
  const getSlotDetails = (slot) => {
    return requests.find((request) => {
      const requestDate = new Date(request.selectedDate);
      return requestDate.getTime() === slot.getTime();
    });
  };

  return (
    <div className="appointments-container">
      <div className="date-picker-container">
        <p>Avukatın Randevu Takvimi:</p>
        <DatePicker
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="yyyy/MM/dd"
        />
      </div>

      <div className="time-slots">
        {timeSlots.map((slot, index) => {
          const slotDetails = getSlotDetails(slot);
          return (
            <div
              key={index}
              className={`time-slot ${slotDetails ? "booked" : ""}`}
              style={{ backgroundColor: slotDetails ? "#f8d7da" : "#d4edda" }}
            >
              {slot.toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Appointments;
