import Accordion from "./Accordion/Accordion";
import Accordion2 from "./Accordion/Accordion2";
import "../css/faq.css";
import React, { useState } from "react";

const FAQ = () => {
  return (
    <div className="faqcontainer">
      <div className="faq">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="faqtitle">Sıkça Sorulan Sorular</h1>
        </div>
        <hr className="hrfaq" />
        <div className="mobileaccordion d-flex align-items-center justify-content-center">
          <Accordion style={{ width: "100%" }} />
          <Accordion2 style={{ width: "100%" }} />
        </div>
      </div>
    </div>
  );
};

export default FAQ;
