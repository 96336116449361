import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getError } from "../../../getError";

const LawyerDetailsPage = ({ onClose }) => {
  const { id } = useParams();
  const [lawyer, setLawyer] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URI}/api/lawyers/${id}`)
      .then((response) => {
        setLawyer(response.data);
      })
      .catch((error) => {
        toast.error(getError(error));
      });
  }, [id]);

  if (!lawyer) return <div>Loading...</div>;

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        height: "90%",
        margin: "10px",
        padding: "30px",
        backgroundColor: "#f7f7f7",
        borderRadius: "12px",
        boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        overflowY: "auto",
        zIndex: 1000,
      }}
    >
      <h3
        style={{
          marginBottom: "20px",
          fontSize: "24px",
          fontWeight: "bold",
          color: "#333",
        }}
      >
        Avukat Detayları
      </h3>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "20px",
          alignItems: "start",
        }}
      >
        <div>
          <h4 style={{ fontSize: "18px", marginBottom: "10px" }}>Bilgiler</h4>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              backgroundColor: "#fff",
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
            }}
          >
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>Adı:</td>
                <td style={{ padding: "8px" }}>{lawyer.name}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>Soyadı:</td>
                <td style={{ padding: "8px" }}>{lawyer.surname}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>Email:</td>
                <td style={{ padding: "8px" }}>{lawyer.email}</td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>Baro No:</td>
                <td style={{ padding: "8px" }}>
                  {lawyer.barNo || "Bilgi Yok"}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>Telefon:</td>
                <td style={{ padding: "8px" }}>
                  {lawyer.phone?.countryCode
                    ? `${lawyer.phone.countryCode} ${lawyer.phone.phoneNumber}`
                    : "Bilgi yok"}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>Branş:</td>
                <td style={{ padding: "8px" }}>
                  {lawyer.branchId.title || "Bilgi yok"}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>Diller:</td>
                <td style={{ padding: "8px" }}>
                  {lawyer.languages?.join(", ") || "Bilgi yok"}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>
                  Lokasyon:
                </td>
                <td style={{ padding: "8px" }}>
                  {lawyer.locationId.country || "Bilgi yok"}
                </td>
              </tr>
              <tr>
                <td
                  key={lawyer.request?._id}
                  style={{ fontWeight: "bold", padding: "8px" }}
                >
                  Randevular:
                </td>
                <td style={{ padding: "8px" }}>
                  {lawyer.request?._id || "Bilgi yok"},
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <h4 style={{ fontSize: "18px", marginBottom: "10px" }}>
            Ek Detaylar
          </h4>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              backgroundColor: "#fff",
              borderRadius: "8px",
              padding: "20px",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
            }}
          >
            <tbody>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>
                  Açıklama:
                </td>
                <td style={{ padding: "8px" }}>
                  {lawyer.description || "Bilgi yok"}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>Adres:</td>
                <td style={{ padding: "8px" }}>
                  {lawyer.address?.description || "Bilgi yok"},{" "}
                  {lawyer.address?.city || "Bilgi yok"},{" "}
                  {lawyer.address?.town || "Bilgi yok"}
                </td>
              </tr>
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>
                  Hakkında:
                </td>
                <td style={{ padding: "8px" }}>
                  {lawyer.about || "Bilgi yok"}
                </td>
              </tr>
              {lawyer.education && lawyer.education.length > 0 ? (
                lawyer.education.map((edu, index) => (
                  <tr key={index}>
                    <td style={{ fontWeight: "bold", padding: "8px" }}>
                      Eğitim:
                    </td>
                    <td style={{ padding: "8px" }}>
                      {edu.school || "Bilgi yok"},{" "}
                      {edu.start
                        ? new Date(edu.start).getFullYear()
                        : "Başlangıç tarihi yok"}{" "}
                      -{" "}
                      {edu.finish
                        ? new Date(edu.finish).getFullYear()
                        : "Bitiş tarihi yok"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td style={{ fontWeight: "bold", padding: "8px" }}>
                    Eğitim:
                  </td>
                  <td style={{ padding: "8px" }}>Bilgi yok</td>
                </tr>
              )}
              <tr>
                <td style={{ fontWeight: "bold", padding: "8px" }}>Deneyim:</td>
                <td style={{ padding: "8px" }}>
                  {lawyer.experience && lawyer.experience.length > 0
                    ? lawyer.experience
                        .filter((exp) => exp.office && exp.start)
                        .map((exp, index) => (
                          <div key={index}>
                            {exp.office} -{" "}
                            {new Date(exp.start).toLocaleDateString()} -{" "}
                            {exp.finish
                              ? new Date(exp.finish).toLocaleDateString()
                              : "Devam ediyor"}
                          </div>
                        ))
                    : "Bilgi yok"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LawyerDetailsPage;
