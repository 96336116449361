import React, { useContext, useState } from "react";
import "../css/lawyerdashboard.css";
import "../css/admindashboard.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import LawyerPersonalDetails from "../components/LawyerDashboard/LawyerPersonalDetails";
import Requests from "../components/AdminDashboard/Requests";
import Users from "../components/AdminDashboard/Users";
import FileManagement from "../components/AdminDashboard/FileManagement";
import admin from "../assets/adminn.png";
import { Store } from "../Store";
import LawyerPasswordChange from "../components/LawyerDashboard/LawyerPasswordChange";
import AllLawyers from "../components/AdminDashboard/Lawyers";

const items = [
  { name: "Avukatlar", icon: "👨‍⚖️" },
  { name: "Danışanlar", icon: "👥" },
  { name: "Randevu Talepleri", icon: "📋" },
  { name: "Dosya İşlemleri", icon: "📂" },
  { name: "Sifre İşlemleri", icon: "🔒" },
];

function AdminDashboard() {
  const { state } = useContext(Store);
  const { lawyerInfo } = state;
  const [selectedItem, setSelectedItem] = useState("Lawyers");

  const showComponent = (item) => {
    if (item === "Avukatlar") {
      return <AllLawyers />;
    } else if (item === "Danışanlar") {
      return <Users />;
    } else if (item === "Randevu Talepleri") {
      return <Requests />;
    } else if (item === "Dosya İşlemleri") {
      return <FileManagement />;
    } else if (item === "Sifre İşlemleri") {
      return <LawyerPasswordChange />;
    } else return <LawyerPersonalDetails  />;
  };

  const onItemClick = (item) => {
    setSelectedItem(item);
  };


  return (
    <>
      <Navbar />
      <div className="d-flex justify-content-center mt-2">
        <div className="lawyerdashbord-container admindashbord-container">
          <div className="lawyerdashbord-leftcard admindashboadr-leftcard">
            <div className="d-flex justify-content-center">
              <div className="lawyerdashboard-photo admindashboard-photo">
                <img
                  src={admin}
                  width="100px"
                  height="90px"
                  className="rounded-circle"
                  alt=""
                />
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-10px",
              }}
            >
            </div>

            <div
              className="d-flex mt-4 justify-content-center m-auto"
              style={{
                width: "152px",
                fontSize: "14px",
                textTransform: "uppercase",
              }}
            >
              {lawyerInfo.name} {lawyerInfo.surname}
            </div>
            <div className="border-bottom"></div>
            <div className="m-4">
              <ul>
                <li style={{ listStyle: "none", marginBottom: "20px" }}>
                  <b style={{ fontSize: "20px" }}>Hesabım </b>{" "}
                </li>
                {items.map((item, index) => (
                  <li
                    className={`leftbox-li adminleftbox-li ${
                      selectedItem === item.name ? "active" : ""
                    }`}
                    key={index}
                    onClick={() => onItemClick(item.name)}
                  >
                    <span className="menu-icon">{item.icon}</span>
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="search-left-border"></div>
          <div className="lawyerdashbord-rightcard admindashbord-rightcard">
            {showComponent(selectedItem)}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AdminDashboard;
