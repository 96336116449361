import React from "react";

const FilterLawyers = ({
  filteredData,
  selectedLawyerId,
  setSelectedLawyerId,
  handleNext,
  handlePrevious,
}) => {
  const handleSelectLawyer = (lawyerId) => {
    setSelectedLawyerId((prev) => (prev === lawyerId ? null : lawyerId));
  };

  return (
    <div>
      <table className="request-table">
        <thead>
          <tr>
            <th>Ad</th>
            <th>Soyad</th>
            <th>İşlem</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.lawyers.map((lawyer) => (
            <tr
              key={lawyer._id}
              style={{
                cursor: "pointer",
                backgroundColor:
                  selectedLawyerId === lawyer._id ? "#858b67" : "white",
              }}
            >
              <td>{lawyer.name}</td>
              <td>{lawyer.surname}</td>
              <td className="admin-btns">
                <div>
                  <button
                    className="fs-5"
                    onClick={() => handleSelectLawyer(lawyer._id)}
                  >
                    Seç
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div
        className="pagination"
        style={{
          display: "flex",
          gap: "10px",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <button
          className="p-1 rounded"
          onClick={handlePrevious}
          disabled={filteredData.page <= 1}
        >
          Önceki
        </button>
        <span>
          Sayfa {filteredData.page} of {filteredData.totalPages}
        </span>
        <button
          className="p-1 rounded"
          onClick={handleNext}
          disabled={filteredData.page >= filteredData.totalPages}
        >
          Sonraki
        </button>
      </div>
    </div>
  );
};

export default FilterLawyers;
