import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/contact.css";
import { toast } from "react-toastify";
import axios from "axios";
import { getError } from "../getError";

const Contact = () => {
  const [contacInfo, setContactInfo] = useState({
    fullName: "",
    email: "",
    topic: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    console.log(contacInfo);
    
    if (
      !contacInfo.fullName ||
      !contacInfo.email ||
      !contacInfo.topic ||
      !contacInfo.description
    ) {
      setLoading(false);
      toast.error("Zorunlu alanlar doldurulmalıdır");
      return;
    }

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/api/supports`,
        {
          ...contacInfo,
        },
      );
      console.log(data);
      
      if (data) {
        toast.success(data.message);
      }
    } catch (error) {
      toast.error(getError(error));
    }finally{
      setContactInfo({
        fullName: "",
        email: "",
        topic: "",
        description: "",
      });
      setLoading(false);
    }
  };

  const handleChanges = (event) => {
    setContactInfo({ ...contacInfo, [event.target.name]: event.target.value });
  };


  return (
    <div className="d-flex contactmain justify-content-center align-items-center mt-5 vh-100">
      <div className="containercontact border border-primary rounded-4 p-5">
        <div className="contactcontents row">
          {/* Sol Trafta İltişim Bilgileri */}
          <div className="col-md-4 mb-3">
            <div>
              <h5 className="fw-bold text-white fs-1">İletişim</h5>
            </div>
            <p className="text-black">
              <strong>Adres:</strong>
              <br />
              72 CHASE SIDE, SUITE 2 POLIPOST BUSINESS CENTRE LONDON UNITED
              KINGDOM N14 5PH
            </p>
            <p className="text-black">
              <strong>Mail:</strong> <br />
              global@avukatlazim.com <br /> turkiye@avukatlazim.com
            </p>
            <p className="text-black">
              <strong>Telefon:</strong>
              <br />
              +44 7947 883746 <br />
              +44 07919 079428
            </p>
          </div>
          {/* Orta Ksımda uye Olma FOrmu */}
          <div className="col-md-4 mb-3">
            <form className="d-flex flex-column">
              <p className="text-white fs-6">
                <strong>İletişime Geç</strong>
              </p>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="İsim ve Soyisim"
                  name="fullName"
                  onChange={handleChanges}
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email Address"
                  name="email"
                  onChange={handleChanges}
                />
              </div>
              <div className="mb-3 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Konu"
                  name="topic"
                  onChange={handleChanges}
                />
              </div>
              </form>
          </div>
          <div className="col-md-4 d-flex flex-column">
            <div className="flex-grow-1 mb-3 mt-5">
              <textarea
                className="form-control description"
                placeholder="Açıklama"
                rows="7"
                name="description"
                onChange={handleChanges}
                ></textarea>
            </div>
            <button type="submit" className="btn btn-dark custom-btn mt-3" disabled={loading} onClick={handleSubmit}>
              GÖNDER
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
