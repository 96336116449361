import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/RequestPage.css";
import Requestbg from "../assets/lawyerHomePageImg.png";
import NavbarMenu from "./NavbarMenu";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./Navbar";
import axios from "axios";
import { getError } from "../getError";
import LoadingBox from "./LoadingBox";
import { Store } from "../Store";
import { useParams } from "react-router-dom";

const RequestPage = () => {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const params = useParams();
  const {status} = params;

  console.log(status) ;
  

  const [requestInfo, setRequestInfo] = useState({
    locationId: "",
    branchId: "",
    description: "",
  });
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [locations, setLocations] = useState([]);
  const [branches, setBranches] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URI}/api/branchs`)
      .then((response) => {
        setBranches(response.data);
      })
      .catch((error) => {
        toast.error(getError(error));
      });
    axios
      .get(`${process.env.REACT_APP_BASE_URI}/api/locations`)
      .then((response) => {
        setLocations(response.data);
      })
      .catch((error) => {
        toast.error(getError(error));
      });
  }, []);

  const handleDateChange = (date, type) => {
    if (type === "start") {
      setStartDate(date);
      if (endDate && date > endDate) {
        toast.error("Son tarih ilk tarihten önce olamaz");
      }
    } else if (type === "end") {
      if (startDate && date < startDate) {
        toast.error("Son tarih ilk tarihten önce olamaz");
        setEndDate(null);
      } else {
        setEndDate(date);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (
      !requestInfo.locationId ||
      !requestInfo.branchId ||
      !startDate ||
      !endDate ||
      !requestInfo.description
    ) {
      toast.error("Zorunlu alanlar doldurulmalıdır");
      return;
    }

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/api/requests`,
        {
          ...requestInfo,
          startDate,
          endDate,
          requestType: status ? status : "tr_tr",
        },
        {
          headers: { Authorization: `Bearer ${userInfo.token}` },
        }
      );

      if (data?.success) {
        toast.success(data.message);
        setRequestInfo({
          locationId: "",
          branchId: "",
          description: "",
        });
      }

      setLoading(false);
    } catch (error) {
      toast.error(getError(error));
      setLoading(false);
    }
  };

  const handleChanges = (event) => {
    setRequestInfo({ ...requestInfo, [event.target.name]: event.target.value });
  };

  return (
    <>
      <Navbar />
      <NavbarMenu />
      <ToastContainer />

      <div className="container-fluid position-relative p-0 vh-100">
        <img
          src={Requestbg}
          alt="Background"
          className="img-fluid w-100 h-100 position-absolute"
          style={{ objectFit: "cover", zIndex: -1 }}
        />
        <div className="overlay d-flex justify-content-center align-items-center vh-100">
          <form className="form-inline text-center" onSubmit={handleSubmit}>
            <select
              className="custom-select form-control mb-2 mx-auto"
              value={requestInfo.locationId}
              name="locationId"
              onChange={handleChanges}
              style={{
                width: "305px",
                height: "40px",
                backgroundImage:
                  "url(\"data:image/svg+xml;charset=UTF8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='10' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' fill-rule='evenodd' d='M2 0l2 2H0z'/%3E%3C/svg%3E\")",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right 15px center",
                fontSize: "15px",
              }}
            >
              <option value="">Ülke Seç</option>
              {locations &&
                locations.map((location) => (
                  <option key={location._id} value={location._id}>
                    {location.country}
                  </option>
                ))}
            </select>
            <select
              className="custom-select form-control mb-2 mx-auto"
              value={requestInfo.branchId}
              name="branchId"
              onChange={handleChanges}
              style={{
                width: "305px",
                height: "40px",
                backgroundImage:
                  "url(\"data:image/svg+xml;charset=UTF8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13' height='10' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' fill-rule='evenodd' d='M2 0l2 2H0z'/%3E%3C/svg%3E\")",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right 15px center",
                fontSize: "15px",
              }}
            >
              <option value="">Branş</option>
              {branches
                ?.sort((a, b) => a.title.localeCompare(b.title))
                .map((item) => (
                  <option key={item._id} value={item._id}>
                    {item.title}
                  </option>
                ))}
            </select>

            <div className="d-flex mb-2 mx-auto">
              <DatePicker
                selected={startDate}
                onChange={(date) => handleDateChange(date, "start")}
                dateFormat="dd.MM.yyyy"
                className="datepickerinput form-control mr-1"
                placeholderText="Tarih Aralığı Seç-İlk"
                style={{ height: "40px", fontSize: "15px" }}
                minDate={new Date()}
                timeZone="local"
              />
              <DatePicker
                selected={endDate}
                onChange={(date) => handleDateChange(date, "end")}
                dateFormat="dd.MM.yyyy"
                className="datepickerinput form-control ml-1"
                placeholderText="Tarih Aralığı Seç-Son"
                style={{ height: "40px", fontSize: "15px" }}
                minDate={new Date()}
                timeZone="local"
              />
            </div>

            <textarea
              className="form-control mb-2 mx-auto"
              placeholder="Hukuki yardım almak istediğiniz konudan kısaca bahsediniz"
              value={requestInfo.description}
              name="description"
              onChange={handleChanges}
              style={{ width: "305px", height: "100px", fontSize: "15px" }}
              maxLength="500"
            />
            <button
              type="submit"
              className="btn btn-dark mx-auto font-weight-bold"
              style={{
                width: "230px",
                height: "50px",
                fontSize: "15px",
                borderRadius: "30px",
              }}
            >
              {loading ? <LoadingBox /> : "Avukat Talep Et"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default RequestPage;
