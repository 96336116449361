import React, { useContext, useRef } from "react";
import Navbar from "../components/Navbar";
import UserHomePageBody from "../components/HomePageBodies/UserHomePageBody";
import LawyerHomePageBody from "../components/HomePageBodies/LawyerHomePageBody";
import Footer from "../components/Footer";
import { Store } from "../Store";
import NavbarMenu from "../components/NavbarMenu";
import VideoSection from "../components/VideoSection";
import Reference from "../components/Reference";
import FAQ from "../components/FAQ";
import Blog from "../components/Blog";
import Contact from "../components/Contact";
import Services from "../components/services/Services";
import "../css/homepage.css";

const HomePage = () => {
  const { state } = useContext(Store);
  const { is_lawyer } = state;

  const blogRef = useRef(null);
  const servicesRef = useRef(null);
  const aboutRef = useRef(null);
  const contactRef = useRef(null);
  
  const scrollToBlog = () => {
    if (blogRef.current) {
      blogRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToServices = () => {
    if (servicesRef.current) {
      servicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToAbout = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="homepage">
      <Navbar />
      <NavbarMenu
        onBlogClick={scrollToBlog}
        onServicesClick={scrollToServices}
        onAboutClick={scrollToAbout}
        onContactClick={scrollToContact}
      />
      {is_lawyer ? <LawyerHomePageBody /> : <UserHomePageBody />}
      <div ref={aboutRef}>
        <VideoSection />
      </div>
      <div ref={servicesRef}>
        <Services />
      </div>
      <div ref={blogRef}>
        <Blog />
      </div>
      <div ref={contactRef}>
        <Contact />
      </div>
      <Reference />
      <FAQ />
      <Footer />
    </div>
  );
};

export default HomePage;
