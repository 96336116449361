import React from "react";
import "../../css/userpastpay.css";

const UserPastPay = () => {
  const payments = [
    {
      date: "01.07.2024",
      amount: "100₺",
      type: "Kredi Kartı",
      description: "Fatura Ödemesi",
    },
    {
      date: "15.07.2024",
      amount: "200₺",
      type: "Banka Transferi",
      description: "Kira Ödemesi",
    },
    {
      date: "30.07.2024",
      amount: "150₺",
      type: "Nakit",
      description: "Alışveriş",
    },
  ];

  return (
    <table className="payment-table">
      <thead>
        <tr>
          <th>Tarih</th>
          <th>Ödeme Miktarı</th>
          <th>Ödeme Türü</th>
          <th>Açıklama</th>
        </tr>
      </thead>
      <tbody>
        {payments.map((payment, index) => (
          <tr key={index}>
            <td>{payment.date}</td>
            <td>{payment.amount}</td>
            <td>{payment.type}</td>
            <td>{payment.description}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default UserPastPay;
