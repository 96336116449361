import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import NavbarMenu from "../components/NavbarMenu";
import "../css/teams.css";
import { toast } from "react-toastify";
import { getError } from "../getError";
import { Store } from "../Store";

const Teams = () => {
  const [lawyers, setLawyers] = useState([]);
  const { state } = useContext(Store);
  const { lawyerInfo } = state;

  useEffect(() => {
    const fetchLawyers = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/api/lawyers`,
          {
            headers: { Authorization: `Bearer ${lawyerInfo?.token}` },
          }
        );

        const updatedLawyers = await Promise.all(
          response.data.map(async (lawyer) => {
            const imageUrl = `${process.env.REACT_APP_BASE_URI}/uploads/image/${lawyer.picture}`;
            try {
              await axios.head(imageUrl);
              return { ...lawyer, pictureUrl: imageUrl };
            } catch (error) {
              return {
                ...lawyer,
                pictureUrl: "https://via.placeholder.com/200",
              };
            }
          })
        );

        setLawyers(updatedLawyers);
      } catch (error) {
        toast.error(getError(error));
      }
    };

    fetchLawyers();
  }, [lawyerInfo]);

  return (
    <>
      <Navbar />
      <NavbarMenu />
      <div className="center flex-wrap gap-4 mb-3 mt-3 d-flex align-items-center justify-content-center">
        {lawyers.map((lawyer) => (
          <div className="card" key={lawyer._id}>
            <img
              src={lawyer.pictureUrl}
              alt={`${lawyer.name} ${lawyer.surname}`}
            />
            <div className="details">
              <h2>{`${lawyer.name} ${lawyer.surname}`}</h2>
              <p>
                {lawyer.role || "Avukat"}, {lawyer.branchId.title || "Branş"}
              </p>
              <p>
                {lawyer.locationId.country || "Ülke"},{" "}
                {lawyer.address?.city || "Şehir"}
              </p>
            </div>
            <p id="info">
              Biyografi: {lawyer.about || "Bilgi bulunmamaktadır."}
            </p>
          </div>
        ))}
      </div>
      <Footer />
    </>
  );
};

export default Teams;
