import "../css/blog.css";
import React from "react";
import blogimage from "../assets/blogimage.jpg";
import blogbilisim from "../assets/bilisim-hukuku.jpg";
import blogis from "../assets/is-hukuku.png";

const Blog = () => {
  return (
    <div className="mainblog d-flex flex-wrap">
      <div className="d-flex align-items-center justify-content-between">
        <h1 className="blogtitle">Blog</h1>
      </div>
      <hr className="hrblog" />
      <div className="cardwrapper d-flex">
        <div className="card">
          <img src={blogbilisim} alt="Blog Cover" className="card-image" />
          <div className="card-content">
            <h2 className="card-title">BİLİŞİM HUKUKU</h2>
            <p className="card-author">Yazar</p>
            <p className="card-description">
              AVUKATLIK DA DİJİTALLEŞİYOR !
              <br />
              Bu dijitalleşme geleneksel hukuk otoritelerinin hoşuna gitmese de,
              katı reklam yasağı ısrarına rağmen legal- tech girişimlerinin
              çoğalmasını sekteye uğratamıyor.
            </p>
            <button className="card-button">İNCELE</button>
          </div>
        </div>
        <div className="card">
          <img src={blogimage} alt="Blog Cover" className="card-image" />
          <div className="card-content">
            <h2 className="card-title">CEZA HUKUKU</h2>
            <p className="card-author">Yazar</p>
            <p className="card-description">
              SOSYAL MEDYADAN HAKARETTE YENİ KARAR
              <br />
              Türk Ceza Kanunu madde. 125 hakaret suçunun dijital platformlar
              üzerinden de işlenebileceğini düzenlemektedir. Sosyal medyada
              hakare...
            </p>
            <button className="card-button">İNCELE</button>
          </div>
        </div>
        <div className="card">
          <img src={blogis} alt="Blog Cover" className="card-image" />
          <div className="card-content">
            <h2 className="card-title">İŞ HUKUKU</h2>
            <p className="card-author">Yazar</p>
            <p className="card-description">
              İŞÇİ ALEYHİNE GETİRİLEN REKABET YASAĞINDA UYGULAMA ŞARTLARI
              <br />
              İşçilik Alacağı konusunda Güncel yargı kararları ışığında iş
              hukuku alanında çalışmakta olan avukatlarla iletişim kurmanıza...
            </p>
            <button className="card-button">İNCELE</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blog;
