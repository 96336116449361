import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import atomyum from "../assets/atomyum.png";
import junius from "../assets/juniuslogo.png";
import learn from "../assets/learnfinity.png";

function CenterMode() {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 4,
    speed: 500,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: "60px", // İsteğe bağlı, telefon modunda padding'i değiştirmek için
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        <div>
          <img
            className="juniustech"
            style={{ width: "65px", height: "83px" }}
            src={junius}
            alt="juniustech"
          />
        </div>
        <div>
          <img
            className="atomyum"
            style={{ width: "137px", height: "38px" }}
            src={atomyum}
            alt="atomyum"
          />
        </div>
        <div>
          <img
            className="learnfinity"
            style={{ width: "200px", height: "70px" }}
            src={learn}
            alt="learnfinity"
          />
        </div>
        <div>
          <img
            className="juniustech"
            style={{ width: "65px", height: "83px" }}
            src={junius}
            alt="juniustech"
          />
        </div>
        <div>
          <img
            className="atomyum"
            style={{ width: "137px", height: "38px" }}
            src={atomyum}
            alt="atomyum"
          />
        </div>
        <div>
          <img
            className="learnfinity"
            style={{ width: "200px", height: "70px" }}
            src={learn}
            alt="learnfinity"
          />
        </div>
      </Slider>
    </div>
  );
}

export default CenterMode;
