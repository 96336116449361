import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../css/style.css";
import homebg from "../assets/homebgleft.png";
import Image from "../assets/homebg.webp";
import whitebg from "../assets/whitebg.png";
import { BiCubeAlt } from "react-icons/bi";
import { RiNewspaperLine } from "react-icons/ri";
import { Store } from "../Store";

const Header = () => {
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);
  const { state } = useContext(Store);
  const { userInfo, lawyerInfo } = state;
  const isLoggedIn = !!userInfo || !!lawyerInfo;
  const navigate = useNavigate();

  const handleCheckboxChange = (checkboxId) => {
    if (!isLoggedIn) {
      toast.info("Lütfen giriş yapın.");
      return;
    }

    setSelectedCheckbox(checkboxId);
    toast.info("Arama sayfasına yönlendiriliyorsunuz");

    // Map checkboxId to query parameters
    const queryParams = {
      1: "tr_tr",
      2: "tr_ab",
      3: "ab_tr",
      4: "ab_ab",
    };

    setTimeout(() => {
      navigate(`/request/${queryParams[checkboxId]}`);
    }, 1500);
  };

  return (
    <section className="contain headerbgright">
      <div className="headermain d-flex flex-row">
        <div className="homebgimage w-50 position-relative">
          <img src={homebg} alt="" />
          <p className="headertitleimg text-center position-absolute">
            Online Hukuki Danışmanlık Platformu
          </p>
        </div>
        <div className="header-right d-flex flex-column align-items-center justify-content-center gap-5">
          <h3 className="header">
            YURTDIŞINDA ve TÜRKİYE'DE <br /> HUKUKİ DANIŞMANLIK İÇİN
            <br />
            TEK ADRES
          </h3>
          <div className="table-headerright d-flex flex-column align-items-center justify-content-center gap-2">
            {[
              "Türkiyedeyim Türkiye Hukuku hakkında danışmanlık almak istiyorum.",
              "Türkiyedeyim Yurtdışı Hukuku hakkında danışmanlık almak istiyorum.",
              "Yurtdışındayım Türkiye Hukuku hakkında danışmanlık almak istiyorum.",
              "Yurtdışındayım Yurtdışı Hukuku hakkında danışmanlık almak istiyorum.",
            ].map((text, index) => {
              const checkboxId = index + 1;
              return (
                <div
                  key={checkboxId}
                  className={`headerleftbox d-flex align-items-center justify-content-start gap-1 ${
                    selectedCheckbox === checkboxId ? "selected" : ""
                  }`}
                  onClick={() => handleCheckboxChange(checkboxId)}
                >
                  <input
                    type="checkbox"
                    className="checkbox-round"
                    checked={selectedCheckbox === checkboxId}
                    readOnly
                  />
                  <label
                    htmlFor={`checkbox${checkboxId}`}
                    style={{ fontSize: "15px" }}
                  >
                    {text}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="headerbottom d-flex">
        <div className="headerbottomleft  d-flex align-items-center justify-content-center gap-5">
          <div className="roundedsection position-relative">
            <img src={Image} alt="avukatlazimimage" />
            <BiCubeAlt className="fa-solid fa-cube position-absolute" />
          </div>
          <div className="mobiledes">
            <h5
              style={{
                fontSize: "24px",
                fontWeight: "700",
                color: "#00242E",
                margin: 0,
              }}
            >
              Yılların Tecrübesi
            </h5>
            <p
              style={{
                fontSize: "15px",
                fontWeight: "600",
                color: "#00242E",
                lineHeight: "15px",
              }}
            >
              Yılların getirdiği tecrübeyle, hukukun üstünlüğü prensibinden
              ayrılmadan, danışanlarımıza hukuki danışmanlık ve avukatlık
              hizmeti sunmaktayız.
            </p>
          </div>
        </div>
        <div className="headerbottomright  d-flex align-items-center justify-content-center gap-5">
          <div className="roundedsection position-relative">
            <img src={whitebg} alt="" style={{ backgroundColor: "white" }} />
            <RiNewspaperLine className="fa-regular fa-newspaper position-absolute" />
          </div>
          <div className="mobiledes">
            <h5
              style={{
                fontSize: "24px",
                fontWeight: "700",
                color: "#ffff",
                margin: 0,
              }}
            >
              Güvenilir Partner
            </h5>
            <p
              style={{
                fontSize: "15px",
                fontWeight: "600",
                color: "#ffff",
                lineHeight: "15px",
              }}
            >
              Çözüm sürecine en kısa sürede müdahil olabilmek amacıyla,
              davalarınızla ilgili destek almak için bizimle iletişime
              geçebilirsiniz.
            </p>
          </div>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default Header;
